<template>
  <SearchView
    :label="$t('app.add_new_observation')"
    :route="route"
    :field-options="fieldOptions"
    :is-searching="isSearching"
    @input:search="onUpdateSearch"
    @click:search="onClickSearch"
    @click:export="onClickExport"
  >
    <template #title>
      <VTitle class="flex justify-baseline items-center route-title">
        {{ $t("app.observations", 2) }}

        <VHint class="ml-4 text-base">
          {{ $t("hints.teaching_evaluations_observations") }}
        </VHint>
      </VTitle>
    </template>

    <template #filters="{ index, filters }">
      <FilterForm
        :index="index"
        :types="types"
        :options="options"
        @keyup:enter="onClickSearch(filters)"
      />
    </template>

    <template #default>
      <slot />
    </template>
  </SearchView>
</template>

<script>
import { provide } from "vue";
// Composables
import useObservation from "@/composables/useObservation";
import useSearch from "@/composables/useSearch";
// Components
import VTitle from "@/components/VTitle";
import VHint from "@/components/VHint";
import FilterForm from "@/components/templates/FilterForm";
import SearchView from "@/components/SearchView";

export default {
  components: {
    VTitle,
    VHint,
    SearchView,
    FilterForm
  },
  setup() {
    // Composables
    const {
      searchFields,
      view_code,
      options,
      route,
      endpoint,
      types,
      filters
    } = useObservation();
    const {
      fieldOptions,
      onClickSearch,
      isSearching,
      onUpdateSearch,
      onClickExport
    } = useSearch({
      types,
      searchFields,
      route,
      endpoint,
      addedFilters: filters
    });

    // Provide
    provide("view_code", view_code);
    provide("types", types);

    return {
      // useObservation
      route,
      types,
      options,
      // useSearch
      fieldOptions,
      onClickSearch,
      isSearching,
      onUpdateSearch,
      onClickExport
    };
  }
};
</script>
